.landingContainer{
    vertical-align: middle;
    display: table-cell;
    width: 100%;
}
.landingContainer h1.title{
    font-size:9rem;
    text-align: center;
    color: white;
    margin-bottom:9vh;
}
.landingContainer p{
  font-size:3.5em;
}
.landingContainer p span.circle{ font-size:2rem; padding: 0.7rem; }
.landingMessage{
    width:100%;
    height: 100vh;
    position: absolute;
    top: -5vh;
    left: 0;
    z-index: 999;
    /* background: url('/images/bg-message.jpg') no-repeat center center fixed; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}